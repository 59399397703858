<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Merchant Table</span>
      <el-button class="btn-add" @click="handleAddMerchant()" size="mini">
        Add
      </el-button>
    </el-card>
    <el-dialog
      :visible.sync="openHours.openHoursVisible"
      title="Open Hours"
      width="600px"
    >
      <el-form inline>
        <div
          v-for="(item, index) in openHours.openHoursParam"
          v-bind:key="index"
        >
          <el-row type="flex" class="row-bg">
            <el-col :span="1">
              {{ item.day }}
            </el-col>
            <el-col :span="6">
              <el-form-item label="开始">
                <el-input
                  v-model="item.startHour"
                  style="width: 60px"
                  placeholder="小时"
                  :disabled="item.closed"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"> : </el-col>
            <el-col :span="5">
              <el-form-item>
                <el-input
                  v-model="item.startMinute"
                  style="width: 60px"
                  placeholder="分钟"
                  :disabled="item.closed"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结束">
                <el-input
                  v-model="item.endHour"
                  style="width: 60px"
                  placeholder="小时"
                  :disabled="item.closed"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"> : </el-col>
            <el-col :span="5">
              <el-form-item>
                <el-input
                  v-model="item.endMinute"
                  style="width: 60px"
                  placeholder="分钟"
                  :disabled="item.closed"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="Closed">
                <el-checkbox
                  v-model="item.closed"
                  @change="handleClosedChange(item.closed, index)"
                >
                </el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submitOpenHours">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="table-container">
      <el-table
        ref="productCateTable"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Name" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="Tags" align="center">
          <template slot-scope="scope">
            <div v-for="(tag, index) in scope.row.merchantTags" v-bind:key="index">
              <p>{{ tag.tag }}{{ tag.primary ? "(primary)" : "" }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Email" align="center">
          <template slot-scope="scope">{{ scope.row.email }}</template>
        </el-table-column>
        <el-table-column label="Address" align="center">
          <template slot-scope="scope">{{ scope.row.address }}</template>
        </el-table-column>
        <el-table-column label="City" align="center">
          <template slot-scope="scope">{{ scope.row.city }}</template>
        </el-table-column>
        <el-table-column label="Province" align="center">
          <template slot-scope="scope">{{ scope.row.province }}</template>
        </el-table-column>
        <el-table-column label="Postal Code" align="center">
          <template slot-scope="scope">{{ scope.row.postalCode }}</template>
        </el-table-column>
        <el-table-column label="Display?" align="center" width="80px">
          <template slot-scope="scope">{{ scope.row.displaInHomePage }}</template>
        </el-table-column>
        <el-table-column label="Keychain" align="center">
          <template slot-scope="scope">{{ scope.row.keyChain }}</template>
        </el-table-column>
        <el-table-column label="Username" align="center">
          <template slot-scope="scope"
            >{{ scope.row.merchantCode }}
            <el-button
              @click="showSetUsername(scope.row)"
              icon="el-icon-edit"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <p>
              <el-button
                size="mini"
                @click="getMenuList(scope.$index, scope.row)"
                >Menus
              </el-button>
            </p>
            <p>
              <el-button size="mini" @click="showSetPassword(scope.row)"
                >Set Password
              </el-button>
            </p>
            <p>
              <el-button size="mini" @click="generateKeyChain(scope.row)">
                Generate KeyChain
              </el-button>
            </p>
            <p>
              <el-button size="mini" @click="resetAdmin(scope.row)">
                Reset Admin
              </el-button>
            </p>
            <p>
              <el-button
                size="mini"
                @click="handleUpdate(scope.$index, scope.row)"
                >编辑
              </el-button>
            </p>
            <p>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除
              </el-button>
            </p>
            <p>
              <el-button size="mini" @click="handleOpenHours(scope.row)"
                >Open Hours</el-button
              >
            </p>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="Set Password"
        :visible.sync="setPasswordVisible"
        width="30%"
      >
        <el-form>
          <el-form-item label="Password">
            <el-input v-model="setPasswordParam.password"> </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="
              setPasswordVisible = false;
              setPasswordParam.password = null;
            "
            >Cancel</el-button
          >
          <el-button type="primary" @click="submitSetPassword()"
            >Submit</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="Set Username"
        :visible.sync="setUsernameVisible"
        width="30%"
      >
        <el-form>
          <el-form-item label="Username">
            <el-input v-model="setUsernameParam.username"> </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="
              setUsernameVisible = false;
              setUsernameParam.username = null;
            "
            >Cancel</el-button
          >
          <el-button type="primary" @click="submitSetUsername()"
            >Submit</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes,prev, pager, next,jumper"
        :page-size="listQuery.pageSize"
        :page-sizes="[5, 10, 15]"
        :current-page.sync="listQuery.pageNum"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  fetchList,
  deleteMerchant,
  setPassword,
  setUsername,
  setHours,
  generateKeyChain,
  assignAdmin,
} from '@/api/merchant';

const defaultOpenHoursParam = {
  day: null,
  startHour: null,
  startMinute: null,
  endHour: null,
  endMinute: null,
  closed: false,
};
export default {
  name: 'merchantList',
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 5,
      },
      setPasswordParam: {
        merchantId: null,
        password: null,
      },
      setPasswordVisible: false,
      setUsernameParam: {
        merchantId: null,
        username: null,
      },
      setUsernameVisible: false,
      openHours: {
        openHoursVisible: false,
        openHoursParam: null,
        merchant: null,
      },
    };
  },
  created() {
    this.getList();
    this.setDefaultOpenHoursParam();
  },
  watch: {
    $route(route) {
      this.getList();
    },
  },
  methods: {
    handleAddMerchant() {
      this.$router.push('/merchantService/addMerchant');
    },
    getList() {
      this.listLoading = true;
      fetchList().then((response) => {
        this.listLoading = false;
        this.list = response.data.data;
        this.total = response.data.total;
      });
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleUpdate(index, row) {
      this.$router.push({
        path: '/merchantService/updateMerchant',
        query: { id: row.id },
      });
    },
    handleDelete(index, row) {
      this.$confirm('Are you sure to delete this merchant?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteMerchant(row.id).then((response) => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
    getMenuList(index, row) {
      this.$router.push({
        path: '/merchantService/merchantMenu',
        query: { id: row.id },
      });
    },
    showSetPassword(merchant) {
      this.setPasswordParam.merchantId = merchant.id;
      this.setPasswordVisible = true;
    },
    submitSetPassword() {
      this.$confirm('Overwrite current password?!', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        setPassword(this.setPasswordParam).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.setPasswordVisible = false;
        });
      });
    },
    showSetUsername(merchant) {
      this.setUsernameParam.merchantId = merchant.id;
      this.setUsernameVisible = true;
    },
    submitSetUsername() {
      this.$confirm('Overwrite current username?!', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        setUsername(this.setUsernameParam).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.setUsernameVisible = false;
          this.setUsernameParam.id = null;
          this.setUsernameParam.username = null;
          this.getList();
        });
      });
    },
    handleOpenHours(merchant) {
      this.openHours.merchant = merchant;
      if (merchant.openHours.length != 7) {
        this.setDefaultOpenHoursParam();
      } else {
        this.openHours.openHoursParam = [];
        merchant.openHours.forEach((x) => this.openHours.openHoursParam.push({ ...x }));
      }
      this.openHours.openHoursVisible = true;
    },
    setDefaultOpenHoursParam() {
      this.openHours.openHoursParam = [];
      for (let i = 1; i <= 7; i++) {
        const param = { ...defaultOpenHoursParam };
        param.day = i;
        this.openHours.openHoursParam.push(param);
      }
    },
    submitOpenHours() {
      this.$confirm('提交营业时间?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        setHours(
          this.openHours.merchant.id,
          this.openHours.openHoursParam,
        ).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.openHours.openHoursVisible = false;
          this.getList();
        });
      });
    },
    handleClosedChange(val, index) {
      if (val) {
        this.openHours.openHoursParam[index].startHour = 0;
        this.openHours.openHoursParam[index].startMinute = 0;
        this.openHours.openHoursParam[index].endHour = 0;
        this.openHours.openHoursParam[index].endMinute = 0;
      }
    },
    generateKeyChain(merchant) {
      this.$confirm('Overwrite keyChain?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        generateKeyChain(merchant.id).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
        });
      });
    },
    resetAdmin(merchant) {
      this.$confirm('Reset Admin?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        assignAdmin({
          merchantId: merchant.id,
        }).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
        });
      });
    },
  },
  filters: {
    tagsArrayToDisplay(tags) {
      return tags.join('\n');
    },
  },
};
</script>

<style scoped>
</style>
